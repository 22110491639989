import React, { Component } from "react";
import FeaturedSites from "./FeaturedSites";
import Styled from "styled-components";
import { MdSearch } from "react-icons/md";
import { Container, Row, Col } from "reactstrap";
import { fetchSites } from "../services/globalBibleApi";
import Media from "../utils/media";

const HeroRow = Styled(Row)`
  h2 {
    color: #FFFFFF;
    font-weight: 700;
    letter-spacing: -.03em;
    margin-bottom: 2.5rem;
    @media (min-width: 992px) {
      max-width: 80%;
    };
  }
`;

const SearchBox = Styled.div`
  background: #FFFFFF;
  border-radius: 2px;
  padding: 1rem;
  display: flex;
  align-items: center;
  position:relative;
  input {
    width: 100%; 
    border: none; 
    margin-left:8px;
    &::placeholder {
      font-style: italic;
    };
  };
  input:focus {outline: none;}
`;

const SearchResults = Styled.div`
  background: #FFFFFF;
  box-shadow: 1px 4px 5px rgba(0,0,0,0.2);
  padding: 0 2rem;
  position:absolute;
  top:97%;
  left: 0;
  width: 100%;
  z-index: 500;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
  a {
    width: 100%;
    display:block;
    text-decoration: none;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #EFEFEF;
    span { 
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      border-color: #0C68F1;
    }
  }
  ${Media.desktop`
  a {
    width: 30%;
  }
  `}
  .highlight {
    font-weight: bold;
  }
`;

const SearchResultTitle = Styled.span`
  display:block;
  color: #201F2B;
`;

const SearchResultUrl = Styled.span`
  display: block;
  color: #B1B1B1;
  font-size: .75rem;
`;

const highlightTerm = (string, term) => {
  const regexp = new RegExp(`(.*?)(${term})(.*)`, "i");
  const matches = string.match(regexp);
  if (!matches) {
    return string;
  }
  return [
    matches[1],
    <span className="highlight">{matches[2]}</span>,
    matches[3]
  ];
};

class Home extends Component {
  state = {
    query: "",
    sites: [],
    results: []
  };

  componentDidMount = () => {
    fetchSites({ limit: 10 }).then(data => {
      this.setState({ sites: data.sites });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.query !== prevState.query) {
      if (this.state.query.length > 2) {
        fetchSites({ query: this.state.query }).then(data => {
          this.setState({ results: data.sites });
        });
      } else {
        this.setState({ results: [] });
      }
    }
  };

  handleChange = event => {
    const query = event.target.value;
    this.setState({ query });
    // const results = query.length
    //   ? this.state.sites.filter(site =>
    //       site.title.toLowerCase().includes(query)
    //     )
    //   : [];
    // this.setState({ results });
  };

  renderSearchResults() {
    return (
      this.state.results.length > 0 && (
        <SearchResults>
          {this.state.results.map(site => (
            <a href={`https://${site.subdomain}.global.bible`} target="_blank">
              <SearchResultTitle>
                {highlightTerm(site.title, this.state.query)}
              </SearchResultTitle>
              <SearchResultUrl>{`https://${site.subdomain}.global.bible`}</SearchResultUrl>
            </a>
          ))}
        </SearchResults>
      )
    );
  }

  render() {
    return (
      <div>
        <Container>
          <HeroRow>
            <Col sm={{ size: 8, order: 2, offset: 2 }}>
              <h2>
                The Bible in over 1,000 languages — and counting. Find your
                Bible now.
              </h2>
              <SearchBox>
                <MdSearch size={"24"} />
                <input
                  type="text"
                  value={this.state.query}
                  onChange={this.handleChange}
                  placeholder="Try searching for a language, country or Bible"
                />
                {this.renderSearchResults()}
              </SearchBox>
            </Col>
          </HeroRow>
        </Container>
        <FeaturedSites sites={this.state.sites} />
      </div>
    );
  }
}

export default Home;
