import React, { Component } from "react";
import Styled from "styled-components";
import { MdSearch } from "react-icons/md";
import { fetchSites } from "../services/globalBibleApi";
import { Container, Row } from "reactstrap";
import { OutboundLink } from "react-ga";

import Paginator from "./Paginator";

const SiteCardsContainer = Styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const SiteCardsRow = Styled(Row)`
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  @media (min-width: 576px) {
    flex-direction: row;
    padding: 0;
  };
`;

const SiteCard = Styled(OutboundLink)`
  background-color: white;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 2px;
  text-decoration: none;
  @media (min-width: 576px) {width: 48%;};
  @media (min-width: 992px) {width: 24%;};
  border-left: solid 4px ${props => props.color};
  box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.05);
    text-decoration: none;
  }
`;

const ListTitle = Styled.h2`
  font-size: .75rem;
  letter-spacing: .1em;
  text-transform: uppercase;
  width: 100%;
  span {
    color: #AEAEAE;
  };
`;

const SiteCardTitle = Styled.h3`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #242424;
  font-size: 1.15rem;
  margin:0;
`;

const SiteCardUrl = Styled.p`
  color: #B1B1B1;
  font-size: .75rem;
  margin:0;  
`;

const SitesSearchBox = Styled.div`
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: .5rem;
  border-bottom: solid 1px #D3D3D3;
`;

const SitesSearchFilter = Styled.input`
  background: transparent;
  padding-left: .5rem;
  border:0;
  outline: none;
  &::placeholder {font-style: italic};
`;

class SiteList extends Component {
  state = {
    query: "",
    sites: [],
    page: 1,
    pageSize: 20,
    totalSites: 0
  };

  fetchPagedSites = () => {
    return fetchSites({
      query: this.state.query,
      limit: this.state.pageSize,
      offset: this.state.pageSize * (this.state.page - 1)
    }).then(data => {
      this.setState({ sites: data.sites, totalSites: data.total });
    });
  };

  componentDidMount = () => {
    this.fetchPagedSites();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.query !== prevState.query) {
      this.setState({ page: 1 });
      this.fetchPagedSites();
    } else if (this.state.page !== prevState.page) {
      this.fetchPagedSites();
    }
  };

  handleFilterChange = event => {
    const query = event.target.value;
    this.setState({ query });
  };

  handlePageChange = page => {
    // let newPage = 1;
    // if (page > 0) {
    //   newPage = page;
    // }
    this.setState({ page });
  };

  render() {
    const pageStart = this.state.pageSize * (this.state.page - 1) + 1;
    const pageEnd = pageStart + this.state.sites.length - 1;
    return (
      <div>
        <SiteCardsContainer>
          <SiteCardsRow>
            <SitesSearchBox>
              <MdSearch size={"24"} color={"#2C3E50"} />
              <SitesSearchFilter
                type="text"
                placeholder="Search for Bible site"
                onChange={this.handleFilterChange}
              />
            </SitesSearchBox>
            <ListTitle>
              Sites{" "}
              <span>
                (
                {this.state.sites.length
                  ? `${pageStart}-${pageEnd} / ${this.state.totalSites}`
                  : ""}
                )
              </span>
            </ListTitle>
            {this.state.sites.map(site => {
              const url = `https://${site.subdomain}.global.bible`;
              return (
                <SiteCard
                  to={url}
                  target="_blank"
                  key={site.id}
                  color={site.primaryColor}
                >
                  <SiteCardTitle>{site.title}</SiteCardTitle>
                  <SiteCardUrl>{site.host}</SiteCardUrl>
                </SiteCard>
              );
            })}
          </SiteCardsRow>
        </SiteCardsContainer>
        <Paginator
          numOfPages={Math.ceil(this.state.totalSites / this.state.pageSize)}
          currentPage={this.state.page}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default SiteList;
