import React, { Component } from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../assets/images/bibles-logo-white.svg";
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const StyledNavbar = Styled(Navbar)`
  background: transparent;
  padding: 1rem;
  @media (min-width: 992px) {
    padding: 2rem 4.5rem;
  };
`;

const GbDropdownToggle = Styled(DropdownToggle)`
  color: #FFFFFF;
  &:hover {color: #FFFFFF;}
`;

const GbDropdownMenu = Styled(DropdownMenu)`
  left: auto;
  right: 0;
  border-radius: 2px;
  border: 0;
  box-shadow: 1px 2px 4px rgba(0,0,0,0.2);
`;

const GbDropdownItem = Styled(DropdownItem)`
  padding: .25rem 1rem;
`;

class Header extends Component {
  state = {
    collapsed: true
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <StyledNavbar dark>
        <NavbarBrand href="/" className="mr-auto">
          <img src={Logo} width="32" alt="Global.Bible Logo" />
        </NavbarBrand>
        <Nav>
          <UncontrolledDropdown nav inNavbar>
            <GbDropdownToggle nav caret>
              Menu
            </GbDropdownToggle>
            <GbDropdownMenu>
              <GbDropdownItem>
                <Link to="/">Home</Link>
              </GbDropdownItem>
              <GbDropdownItem>
                <Link to="/sites">All Sites</Link>
              </GbDropdownItem>
              <GbDropdownItem>
                <Link to="/contact">Contact Us</Link>
              </GbDropdownItem>
            </GbDropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </StyledNavbar>
    );
  }
}

export default Header;
