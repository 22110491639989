import React from "react";
import Styled from "styled-components";
import Logo from "../assets/images/global-bible-logo-horiz-bk.svg";

const SiteFooter = Styled.footer`
  font-family: 'Noto Sans', sans-serif;
  text-align:center;
  padding: 2rem;
  p {
    font-size: 0.75rem;
    color: #7E8EA8;
  }
`;

const Footer = props => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <SiteFooter>
      <p>
        <img src={Logo} width="110" alt="Global.Bible Logo" />
      </p>
      <p>&copy; {year} American Bible Society</p>
    </SiteFooter>
  );
};
export default Footer;
