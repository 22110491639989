import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { MdChevronLeft } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";

const List = Styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 8px 0;
`;

const ListItem = Styled.div`
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  position: relative;
  font-size: 1.2rem;
  color: rgba(0,0,0,0.5);
  text-align: center;
  cursor: pointer;
  margin: 1px;
  &:hover, &.selected {
      color: white;
      background: #666666;
  }
`;

const Paginator = props => {
  const pages = [];
  for (let page = 1; page <= props.numOfPages; page += 1) {
    pages.push(page);
  }

  const handlePageChange = newPage => {
    if (newPage > 0 && newPage <= props.numOfPages) {
      props.onPageChange(newPage);
    }
  };

  return (
    <List>
      <ListItem onClick={() => handlePageChange(props.currentPage - 1)}>
        <MdChevronLeft style={{ fontSize: "1.8re" }} />
      </ListItem>
      {pages.map(page => {
        const distance = Math.abs(props.currentPage - page);
        if (
          distance <= 5 ||
          (page >= props.currentPage && page <= 11) ||
          (page <= props.currentPage && page >= pages.length - 11)
        )
          return (
            <ListItem
              className={props.currentPage === page ? "selected" : ""}
              key={page}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </ListItem>
          );
        return null;
      })}
      <ListItem onClick={() => handlePageChange(props.currentPage + 1)}>
        <MdChevronRight style={{ fontSize: "1.8re" }} />
      </ListItem>
    </List>
  );
};

Paginator.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  numOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired
};

Paginator.defaultProps = {};

export default Paginator;
