import { css } from "styled-components";
import { isMobileDevice } from "./mobile";

export const mediaSizes = {
  giant: isMobileDevice() ? 9999 : 1170,
  desktop: isMobileDevice() ? 9999 : 840,
  tablet: 768,
  phone: 300
};

// iterate through the sizes and create a media template
const media = Object.keys(mediaSizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = mediaSizes[label] / 16;
  // eslint-disable-next-line no-param-reassign
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export default media;

// // Usage
// const Container = styled.div`
//   color: #333;
//   ${media.desktop`padding: 0 20px;`}
//   ${media.tablet`padding: 0 10px;`}
//   ${media.phone`padding: 0 5px;`}
// `
