import React from "react";
import SiteCard from "./SiteCard";
import Styled from "styled-components";

const FeaturedSitesWrapper = Styled.div`
  display: flex;
  height: 100%;
  margin-bottom: -50px; /* maximum width of scrollbar */
  padding-bottom: 50px; /* maximum width of scrollbar */
  overflow-y: hidden;
  overflow-x: scroll;
  box-sizing: content-box;
`;

const ScrollContainer = Styled.div`
  overflow:hidden;
  display: flex;
  height: 260px;
  position: relative;
  margin-left: -24px;
  top: 100px;
  margin-bottom:90px;
`;

const FeaturedSites = props => (
  <ScrollContainer>
    <FeaturedSitesWrapper>
      {props.sites.map(site => <SiteCard site={site} />)}
    </FeaturedSitesWrapper>
  </ScrollContainer>
);

export default FeaturedSites;
