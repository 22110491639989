import React from "react";
import Styled from "styled-components";
import { OutboundLink } from "react-ga";

const Card = Styled(OutboundLink)`
  display: block;
  margin-right: 24px;
  padding: 1em;
  width: 150px;
  min-width: 150px;
  height: 190px;
  color: #FFFFFF;
  position: relative;
  border-radius: 2px;
  box-shadow: 1px 2px 6px rgba(0,0,0,0.25);
  cursor: pointer;
  transition: all .2s ease-in-out;
  text-decoration: none;
  &:hover {transform: scale(1.05); text-decoration:none; color: #FFFFFF;};  
  h3 {
    background-color: #201F2B;
    color: #FFFFFF;
    display:inline-block;
    padding: 0.35rem;
    font-size: 0.8125rem;
  };
  h4 {
    font-size: 0.75rem;
    opacity: .75;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* There is probably a way cooler react-y way to do this... */
  &:nth-child(8n+1) {background-color:#0C68F1; top: 0px;  hgroup {position:relative; top:10px;}};
  &:nth-child(8n+2) {background-color:#860CF1; top: 25px; hgroup {position:relative; top:50px;}};
  &:nth-child(8n+3) {background-color:#129546; top: 10px; hgroup {position:relative; top:20px;}};
  &:nth-child(8n+4) {background-color:#E5A21B; top: 35px; hgroup {position:relative; top:60px;}};
  &:nth-child(8n+5) {background-color:#F10C6F; top: 0px;  hgroup {position:relative; top:10px;}};
  &:nth-child(8n+6) {background-color:#5364FF; top: 25px; hgroup {position:relative; top:40px;}};
  &:nth-child(8n+7) {background-color:#51ADB4; top: 5px;  hgroup {position:relative; top:16px;}};
  &:nth-child(8n+8) {background-color:#F10CF1; top: 35px; hgroup {position:relative; top:70px;}};
`;

const SiteCard = props => (
  <Card to={`https://${props.site.host}`} target="_blank">
    <hgroup>
      <h3>{props.site.title}</h3>
      <h4>{props.site.host}</h4>
    </hgroup>
  </Card>
);

export default SiteCard;
