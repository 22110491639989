import React from "react";
import Styled from "styled-components";
import { Container } from "reactstrap";

const ContactForm = Styled.iframe`
width: 100%;
height: 600px;
border: none;
`;

const Contact = () => {
  return (
    <Container>
      <h2>Contact Us</h2>
      <ContactForm src="https://bible.supportbee.com/web_tickets/new?embed=true&locale=en&captcha=true&ticket[forwarding_address_id]=21398" />
    </Container>
  );
};

export default Contact;
