export const isMobileDevice = () =>
  (typeof window !== "undefined" &&
    typeof window.orientation !== "undefined") ||
  (typeof navigator !== "undefined" &&
    navigator.userAgent.indexOf("IEMobile") !== -1);

export const isTabletDevice = () =>
  isMobileDevice() && window && window.innerWidth >= 500;

export const isPhoneDevice = () => isMobileDevice() && !isTabletDevice();
