import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withTracker } from "./withTracker";

import Home from "./components/Home";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SiteList from "./components/SiteList";
import Styled from "styled-components";
import IconBackground from "./assets/images/icons-pattern.svg";

const SiteWrapper = Styled.div`
  font-family: 'Noto Sans', sans-serif;
`;

const PatternWrapper = Styled.div`
  background-color: #201F2b;
  background-image: url(${IconBackground});
  background-size: 1200px 650px;
  background-position: center top;
`;

class App extends Component {
  render() {
    return (
      <SiteWrapper>
        <PatternWrapper>
          <Header />
          <Route exact path="/" component={withTracker(Home)} />
        </PatternWrapper>
        <Switch>
          <Route exact path="/sites" component={withTracker(SiteList)} />
          <Route exact path="/contact" component={withTracker(Contact)} />
        </Switch>
        <Footer />
      </SiteWrapper>
    );
  }
}

export default App;
